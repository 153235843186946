import React from 'react';
import { useNavigate } from 'react-router-dom';
import './css/Homepage.css';
import { Helmet } from 'react-helmet-async';


const Homepage = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/login');
  };

  const handleTransaction = () => {
    navigate('/tracking');
  };

  return (
    <div className="homepage-container">

      <Helmet>
        <title>SenoPay || reliable || transparent</title>
      </Helmet>
      {/* Header */}
      <header className="header">
        <div className="header-info">
          <p><i className="fas fa-map-marker-alt"></i> SenoPay</p>
          <p><i className="fas fa-clock"></i> </p>
        </div>
        <div className="header-contact">
          <p><i className="fas fa-envelope"></i> info@senoPay.com</p>
        </div>
      </header>

      {/* Hero Section */}
      <div className="hero">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <h1 className="hero-title">SenoPay</h1>
          <p className="sub-text">MOVE YOUR MONEY AROUND THE WORLD WITH MOBILE WALLET AND SenoPay</p>
          <p>Just link your M-WALLET and SenoPay accounts and enjoy a world of possibilities.</p>
        </div>
        <div className="hero-buttons">
          <button className="btn blue" onClick={handleTransaction}>Track Payment</button>
          <button className="btn red" onClick={handleNavigation}>Accept Payment</button>
          <button className="btn grey" onClick={handleNavigation}>Cancel Payment</button>
        </div>
      </div>

      {/* About Us Section */}
      <section className="about-us">
        <h2>About Us</h2>
        <p>
          SenoPay is your go-to solution for fast, secure, and seamless peer-to-peer money transfers.
          We enable users to transfer money worldwide with just a few clicks, ensuring reliable service
          every step of the way.
        </p>
      </section>

      {/* Our Services Section */}
      <section className="services">
        <h2>Our Services</h2>
        <div className="services-container">
          <div className="service-card">
            <div className="service-icon"><i className="fas fa-shield-alt"></i></div>
            <h3>Secure Transfers</h3>
            <p>Experience the peace of mind that comes with our top-notch security systems.</p>
          </div>
          <div className="service-card">
            <div className="service-icon"><i className="fas fa-globe"></i></div>
            <h3>International Reach</h3>
            <p>Send money to friends and family across the globe, hassle-free.</p>
          </div>
          <div className="service-card">
            <div className="service-icon"><i className="fas fa-mobile-alt"></i></div>
            <h3>Mobile Wallet Integration</h3>
            <p>Seamlessly connect your mobile wallet and manage transfers with ease.</p>
          </div>
        </div>
      </section>

      {/* Get Started Section */}
      <section className="get-started">
        <h2>Ready to Get Started?</h2>
        <button className="btn green" onClick={handleNavigation}>Get Started</button>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2024 SenoPay. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Homepage;
