// src/TrackingPage.js
import React, { useState } from 'react';
import { getDatabase, ref, get, query, orderByChild, equalTo } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import './css/Tracking.css'; // Import the CSS file

const TrackingPage = () => {
    const [trackingNumber, setTrackingNumber] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setTrackingNumber(e.target.value.trim()); // Trim whitespace
        setError(null); // Reset error on new input
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const db = getDatabase();
            const transactionsRef = ref(db, 'transactions');
            
            // Log all transactions for debugging
            const allSnapshot = await get(transactionsRef);

            // Create query for the specific tracking number
            const trackingQuery = query(transactionsRef, orderByChild('trackingNumber'), equalTo(trackingNumber));

            const snapshot = await get(trackingQuery);
            

            if (snapshot.exists()) {
                const transactionKey = Object.keys(snapshot.val())[0]; // Get the key of the first matching transaction
                navigate(`/tracking/${transactionKey}`); // Navigate to TransactionDetails page with the transaction key
            } else {
                setError('Tracking number does not exist.');
            }
        } catch (err) {
            setError('Error fetching data: ' + err.message);
        }
    };

    return (
        <div className="tracking-page">
            <div className="overlay">
                <h2>MTCN Number</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={trackingNumber}
                        onChange={handleChange}
                        placeholder="Tracking Number"
                        required
                    />
                    <button type="submit">Track</button>
                </form>
                {error && <div className="error-message">{error}</div>}
            </div>
        </div>
    );
};

export default TrackingPage;
