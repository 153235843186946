// src/App.js
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "./HomePage"; // Import Homepage component
import LoginForm from "./LoginForm";
import TransactionDetails from "./TrackingPage";
import ConfirmLogin from "./ConfirmLogin";
import SubmitTransaction from "./submittransaction"; // Import the new component
import TrackingPage from "./tracking";
import UserTable from "./usertable";
import UpdateForm from "./updatetransaction";
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} /> {/* Render Homepage on root */}
          <Route path="/login" element={<LoginForm />} /> {/* Directly use LoginForm */}
          <Route path="/confirm-login" element={<ConfirmLogin />} />
          <Route path="/tracking" element={<TrackingPage />} />
          <Route path="/tracking/:trackingNumber" element={<TransactionDetails />} />
          <Route path="/mysubmittransaction" element={<SubmitTransaction />} /> {/* Add the new route */}
          <Route path="/mybasetablex" element={<UserTable/>} />
          <Route path="/updatetransact" element={<UpdateForm/>} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
