// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get,onValue,update } from "firebase/database"; // Get only getDatabase

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDEyj63Xj1Fx_Sy8fecFEcWaoMkusLd-pk",
  authDomain: "servespay.firebaseapp.com",
  databaseURL: "https://servespay-default-rtdb.firebaseio.com",
  projectId: "servespay",
  storageBucket: "servespay.appspot.com",
  messagingSenderId: "924086673984",
  appId: "1:924086673984:web:c13bf546d7fd4d4d2535a5",
  measurementId: "G-PGM9DMR9R3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app); // Initialize Realtime Database

// Export necessary objects
export { database, ref, get,onValue,update };